import { Component, OnInit, Input } from '@angular/core';
import { TileHouse } from './../../../model/tile-house';

@Component({
  selector: 'app-house-images',
  templateUrl: './house-images.component.html',
  styleUrls: ['./house-images.component.scss']
})
export class HouseImagesComponent implements OnInit {
  @Input() currentHouse: TileHouse;
  constructor() {}

  ngOnInit() {
console.log(this.currentHouse.photosNames);


  }
}
