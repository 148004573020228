import { Component, OnInit } from '@angular/core';
import { TileService } from './../../services/tile.service';
import { TileHouse } from './../../model/tile-house';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  tiles: TileHouse[];

  innerWidth: number;
  tileHight: number;
  housePhotos: string[];
  currentHouse: TileHouse;
  images: any[];
  slideIndex = 1;
  activateMyModal: boolean;

  public viewerOptions: any = {
    navbar: false,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      prev: 4,
      play: {
        show: 4,
        size: 'large'
      },
      next: 4,
      rotateLeft: 4,
      rotateRight: 4,
      flipHorizontal: 4,
      flipVertical: 4
    }
  };

  houseInfoName =
    '<h4 class="modal-title w-100" id="myModalLabel">Име на къща</h4>';
  houseInfoFooter = '<h3>Площ:</h3>';
  housePhotosView = '';
  housePhotosSrc = '../../../assets/img/tiles/1A_l.jpg';

  getTiles(): void {
    this.tileService.getTiles().subscribe(tiles => (this.tiles = tiles));
    console.log(this.tiles);
  }

  onClickMe(currentTile: TileHouse) {
    this.housePhotosView = '';
    this.currentHouse = currentTile;
    this.housePhotos = currentTile.photosNames;
    console.log(currentTile.photosNames);
    this.activateMyModal = true;
    this.preparePhotos();
    this.setLightBox();
  }
  preparePhotos(){
    for (let i = 0; i < this.housePhotos.length; i++) {
      this.housePhotosView = this.housePhotosView + '<mat-grid-tile><img src="../../../assets/img/tiles/' + this.housePhotos[i] + '" [viewerOptions]="viewerOptions" ngxViewer></mat-grid-tile>'
    }
    console.log(
      'housePhotosView HTML Code: ' + this.housePhotosView
    );
  };
  setLightBox() {
    for (let i = 0; i < this.housePhotos.length; i++) {
      this.images = [];
      // tslint:disable-next-line:max-line-length
      console.log('==================================== i = ' + i);
      console.log(
        '../../../assets/img/tiles/' +
        this.housePhotos[i] +
        ', thumbnail: ../../../assets/img/tiles/' +
        this.currentHouse.imgSmall +
        ', title: ' +
        this.currentHouse.tileName
      );
      console.log('====================================');
      this.images.push({
        source: '../../../assets/img/tiles/' + this.housePhotos[i],
        thumbnail: '../../../assets/img/tiles/' + this.currentHouse.imgSmall,
        title: '' + this.currentHouse.tileName
      });
      // this.housePhotosView = this.housePhotosView + '<mat-grid-tile><img src="../../../assets/img/tiles/' + this.housePhotos[i] + ' [viewerOptions]="viewerOptions" ngxViewer></mat-grid-tile>'
    }
    console.log(
      '=== setLightBox: housePhotosView HTML Code : ' + this.housePhotosView
    );
    this.houseInfoName =
      '<h4 class="modal-title w-100" id="myModalLabel">' +
      this.currentHouse.tileName +
      '</h4>';
    this.houseInfoFooter =
      '<h3>Площ: ' + this.currentHouse.areaAsText + 'кв.м</h3>';
    document.getElementById('showButton').click();
  }

  constructor(private tileService: TileService) { }

  ngOnInit() {
    this.getTiles();
    this.innerWidth = window.innerWidth;
    this.tileHight = 400;
  }
}
