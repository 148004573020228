

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactsComponent } from './components/contacts/contacts.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { AboutComponent } from './components/about/about.component';
import { ConstructionComponent} from './components/construction/construction.component';
import { PriceComponent } from './components/price/price.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomePageComponent } from './components/home-page/home-page.component';


// import { DashboardComponent }   from './dashboard/dashboard.component';
// import { HeroesComponent }      from './heroes/heroes.component';
// import { HeroDetailComponent }  from './hero-detail/hero-detail.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: HomePageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'about', component: AboutComponent },
  // { path: 'detail/:id', component: HeroDetailComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'construction', component: ConstructionComponent },
  { path: 'price', component: PriceComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'models.html', component: ProjectsComponent },
  { path: 'prices.html', component: PriceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
