import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { ConstructionComponent } from './components/construction/construction.component';
import { PriceComponent } from './components/price/price.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { AboutComponent } from './components/about/about.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomePageComponent } from './components/home-page/home-page.component';
// import { AccordionModule } from 'primeng/accordion';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxGalleryModule } from 'ngx-gallery';
import { MatIconModule } from '@angular/material/icon';
import { AngularTextTruncateModule } from './components/commons/angular-text-truncate/angular-text-truncate.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { AppRoutingModule } from './app-routing.module';
import { HouseImagesComponent } from './components/commons/house-images/house-images.component';
// import { LightboxModule } from 'primeng/lightbox';
import { NgxViewerModule } from 'ngx-viewer';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    ConstructionComponent,
    PriceComponent,
    ProjectsComponent,
    ContactsComponent,
    AboutComponent,
    FaqComponent,
    HomePageComponent,
    HouseImagesComponent

    // AngularTextTruncateModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    MatExpansionModule,
    NgxGalleryModule,
    MatIconModule,
    AngularTextTruncateModule,
    MatGridListModule,
    NgxViewerModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
