import { Component, OnInit } from '@angular/core';

import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation
} from 'ngx-gallery';
import { Comment } from './../../model/comment';
import { COMMENTS } from './../../data/coments-list';
import { CommentService } from './../../services/comment.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  comments: Comment[];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  screenWidth: string;
  title = 'app';
  textMessage = '';
  textLength = 50;
  endingText = '<span>...</span>';
  labels = [];
  getComments(): void {
    this.commentService
      .getComments()
      .subscribe(comments => (this.comments = comments));
  }

  constructor(private commentService: CommentService) {}

  ngOnInit(): void {
    this.getComments();
    if (window.screen.width < 600) {
      this.screenWidth = window.screen.width.toString();
      console.log('screen is', this.screenWidth);

      // 768px portrait
      this.galleryOptions = [
        {
          width: '100%',
          height: '300px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Fade,
          imageAutoPlay: false,
          imageAutoPlayPauseOnHover: true,
          previewAutoPlay: false,
          previewAutoPlayPauseOnHover: true
        },
        // max-width 800
        {
          breakpoint: 350,
          width: '100%',
          height: 'auto',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
        },
        // max-width 400
        {
          breakpoint: 200,
          preview: false
        }
      ];
    } else {
      this.galleryOptions = [
        {
          width: '600px',
          height: '400px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Fade,
          imageAutoPlay: false,
          imageAutoPlayPauseOnHover: true,
          previewAutoPlay: false,
          previewAutoPlayPauseOnHover: true
        },
        // max-width 800
        {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
        },
        // max-width 400
        {
          breakpoint: 400,
          preview: false
        }
      ];
    }
    this.galleryImages = [
      {
        small: '../../../assets/img/last_projects/1-small.jpg',
        medium: '../../../assets/img/last_projects/1-medium.jpg',
        big: '../../../assets/img/last_projects/1-big.jpg'
      },
      {
        small: '../../../assets/img/last_projects/2-small.jpg',
        medium: '../../../assets/img/last_projects/2-medium.jpg',
        big: '../../../assets/img/last_projects/2-big.jpg'
      },
      {
        small: '../../../assets/img/last_projects/3-small.jpg',
        medium: '../../../assets/img/last_projects/3-medium.jpg',
        big: '../../../assets/img/last_projects/3-big.jpg'
      },
      {
        small: '../../../assets/img/last_projects/4-small.jpg',
        medium: '../../../assets/img/last_projects/4-medium.jpg',
        big: '../../../assets/img/last_projects/4-big.jpg'
      },
      {
        small: '../../../assets/img/last_projects/5-small.jpg',
        medium: '../../../assets/img/last_projects/5-medium.jpg',
        big: '../../../assets/img/last_projects/5-big.jpg'
      },
      {
        small: '../../../assets/img/last_projects/6-small.jpg',
        medium: '../../../assets/img/last_projects/6-medium.jpg',
        big: '../../../assets/img/last_projects/6-big.jpg'
      }
    ];
  }
}
