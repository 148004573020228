import { Component, OnInit } from '@angular/core';
import { TileService } from './../../services/tile.service';
import { TileHouse } from './../../model/tile-house';

// export interface Tile {
//   imagePath: string;
//   cols: number;
//   rows: number;
//   text: string;
// }

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss']
})
export class ConstructionComponent implements OnInit {
  tiles: TileHouse[];

  innerWidth: number;
  tileHight: number;

  getTiles(): void {
    this.tileService.getTiles().subscribe(tiles => (this.tiles = tiles));
    console.log(this.tiles);
  }
  // tiles: Tile[] = [
  //   {
  //     text: 'Текст, който не е ясно как ще се побере',
  //     cols: 1,
  //     rows: 1,
  //     imagePath: '../../../assets/img/last_projects/1-small.jpg'
  //   },
  //   {
  //     text: 'Two',
  //     cols: 2,
  //     rows: 1,
  //     imagePath: '../../../assets/img/last_projects/2-small.jpg'
  //   },
  //   {
  //     text: 'Three',
  //     cols: 3,
  //     rows: 1,
  //     imagePath: '../../../assets/img/last_projects/3-small.jpg'
  //   },
  //   {
  //     text: 'Four',
  //     cols: 4,
  //     rows: 1,
  //     imagePath: '../../../assets/img/last_projects/4-small.jpg'
  //   }
  // ];
  constructor(private tileService: TileService) {}

  ngOnInit() {
    this.getTiles();
    this.innerWidth = window.innerWidth;
    this.tileHight = 400;
  }
}
