
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { TileHouse } from './../model/tile-house';
import { TILES } from './../data/tiles-list';
@Injectable({
  providedIn: 'root'
})
export class TileService {
  constructor() {}
  getTiles(): Observable<TileHouse[]> {
    // TODO: send the message _after_ fetching the heroes
    // this.messageService.add('HeroService: fetched heroes');
    return of(TILES);
  }

  getTile(tileName: string): Observable<TileHouse> {
    // TODO: send the message _after_ fetching the hero
    // this.messageService.add(`HeroService: fetched hero id=${id}`);
    return of(TILES.find(comment => comment.tileName === tileName));
  }
}
