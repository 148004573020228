import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {
  name = 'M791,524L780,586L896,584Z';
  constructor() {

   }

  ngOnInit() {
  }

}
