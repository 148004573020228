import { TileHouse } from './../model/tile-house';
export const TILES: TileHouse[] = [
  {
    аrea: 6,
    tileName: '1A',
    imgSmall: '1A_s.jpg',
    imgLarge: '1A_l.jpg',
    areaAsText: '6.00',
    photosNames: ['1A_l.jpg']
  },

  {
    аrea: 13.6,
    tileName: '1B',
    imgSmall: '1B_s.jpg',
    imgLarge: '1B_l.jpg',
    areaAsText: '13.60',
    photosNames: ['1B_l.jpg']
  },

  {
    аrea: 28,
    tileName: '1C',
    imgSmall: '1C_s.jpg',
    imgLarge: '1C_l.jpg',
    areaAsText: '28.00',
    photosNames: ['1C_l1.jpg', '1C_l2.jpg', '1C_l3.jpg']
  },

  {
    аrea: 40.15,
    tileName: '2A',
    imgSmall: '2A_s.jpg',
    imgLarge: '2A_l.jpg',
    areaAsText: '40.15',
    photosNames: ['2A_l1.jpg', '2A_l2.jpg']
  },

  {
    аrea: 42,
    tileName: '2B',
    imgSmall: '2B_s.jpg',
    imgLarge: '2B_l.jpg',
    areaAsText: '42.00',
    photosNames: ['2B_l1.jpg', '2B_l2.jpg']
  },

  {
    аrea: 45.68,
    tileName: '2C',
    imgSmall: '2C_s.jpg',
    imgLarge: '2C_l.jpg',
    areaAsText: '45.68',
    photosNames: ['2C_l1.jpg', '2C_l2.jpg']
  },

  {
    аrea: 47.36,
    tileName: '3A',
    imgSmall: '3A_s.jpg',
    imgLarge: '3A_l.jpg',
    areaAsText: '47.36',
    photosNames: ['3A_l1.jpg', '3A_l2.jpg']
  },

  {
    аrea: 50.25,
    tileName: '3B',
    imgSmall: '3B_s.jpg',
    imgLarge: '3B_l.jpg',
    areaAsText: '50.25',
    photosNames: ['3B_l1.jpg', '3B_l2.jpg']
  },

  {
    аrea: 50.42,
    tileName: '3C',
    imgSmall: '3C_s.jpg',
    imgLarge: '3C_l.jpg',
    areaAsText: '50.42',
    photosNames: ['3C_l1.jpg', '3C_l2.jpg']
  },

  {
    аrea: 54,
    tileName: '4A',
    imgSmall: '4A_s.jpg',
    imgLarge: '4A_l.jpg',
    areaAsText: '54.00',
    photosNames: ['4A_l1.jpg', '4A_l2.jpg']
  },

  {
    аrea: 59.5,
    tileName: '4B',
    imgSmall: '4B_s.jpg',
    imgLarge: '4B_l.jpg',
    areaAsText: '59.50',
    photosNames: ['4B_l1.jpg', '4B_l2.jpg']
  },

  {
    аrea: 59.94,
    tileName: '4C',
    imgSmall: '4C_s.jpg',
    imgLarge: '4C_l.jpg',
    areaAsText: '59.94',
    photosNames: ['4C_l1.jpg', '4C_l2.jpg']
  },

  {
    аrea: 67.1,
    tileName: '5A',
    imgSmall: '5A_s.jpg',
    imgLarge: '5A_l.jpg',
    areaAsText: '67.10',
    photosNames: ['5A_l1.jpg', '5A_l2.jpg']
  },

  {
    аrea: 71.28,
    tileName: '5B',
    imgSmall: '5B_s.jpg',
    imgLarge: '5B_l.jpg',
    areaAsText: '71.28',
    photosNames: ['5B_l1.jpg', '5B_l2.jpg']
  },

  {
    аrea: 76.83,
    tileName: '5C',
    imgSmall: '5C_s.jpg',
    imgLarge: '5C_l.jpg',
    areaAsText: '76.83',
    photosNames: ['5C_l1.jpg', '5C_l2.jpg']
  },

  {
    аrea: 77.7,
    tileName: '6A',
    imgSmall: '6A_s.jpg',
    imgLarge: '6A_l.jpg',
    areaAsText: '77.70',
    photosNames: ['6A_l1.jpg', '6A_l2.jpg']
  },

  {
    аrea: 80.06,
    tileName: '6B',
    imgSmall: '6B_s.jpg',
    imgLarge: '6B_l.jpg',
    areaAsText: '80.06',
    photosNames: ['6B_l1.jpg', '6B_l2.jpg']
  },

  {
    аrea: 86.28,
    tileName: '6C',
    imgSmall: '6C_s.jpg',
    imgLarge: '6C_l.jpg',
    areaAsText: '86.28',
    photosNames: ['6C_l1.jpg', '6C_l2.jpg']
  },

  {
    аrea: 86.4,
    tileName: '7A',
    imgSmall: '7A_s.jpg',
    imgLarge: '7A_l.jpg',
    areaAsText: '86.40',
    photosNames: ['7A_l1.jpg', '7A_l2.jpg']
  },

  {
    аrea: 87,
    tileName: '7B',
    imgSmall: '7B_s.jpg',
    imgLarge: '7B_l.jpg',
    areaAsText: '87.00',
    photosNames: ['7B_l1.jpg', '7B_l2.jpg']
  },

  {
    аrea: 91.85,
    tileName: '7C',
    imgSmall: '7C_s.jpg',
    imgLarge: '7C_l.jpg',
    areaAsText: '91.85',
    photosNames: ['7C_l1.jpg', '7C_l2.jpg']
  },

  {
    аrea: 103.32,
    tileName: '8A',
    imgSmall: '8A_s.jpg',
    imgLarge: '8A_l.jpg',
    areaAsText: '103.32',
    photosNames: ['8A_l1.jpg', '8A_l2.jpg', '8A_l3.jpg']
  },

  {
    аrea: 106.87,
    tileName: '8B',
    imgSmall: '8B_s.jpg',
    imgLarge: '8B_l.jpg',
    areaAsText: '106.87',
    photosNames: ['8B_l1.jpg', '8B_l2.jpg', '8B_l3.jpg']
  },

  {
    аrea: 107.18,
    tileName: '8C',
    imgSmall: '8C_s.jpg',
    imgLarge: '8C_l.jpg',
    areaAsText: '107.18',
    photosNames: ['8C_l1.jpg', '8C_l2.jpg']
  },

  {
    аrea: 117.07,
    tileName: '9A',
    imgSmall: '9A_s.jpg',
    imgLarge: '9A_l.jpg',
    areaAsText: '117.07',
    photosNames: ['9A_l1.jpg', '9A_l2.jpg']
  },

  {
    аrea: 117.69,
    tileName: '9B',
    imgSmall: '9B_s.jpg',
    imgLarge: '9B_l.jpg',
    areaAsText: '117.69',
    photosNames: ['9B_l1.jpg', '9B_l2.jpg', '9B_l3.jpg']
  },

  {
    аrea: 122.99,
    tileName: '9C',
    imgSmall: '9C_s.jpg',
    imgLarge: '9C_l.jpg',
    areaAsText: '122.99',
    photosNames: ['9C_l1.jpg', '9C_l2.jpg', '9C_l3.jpg']
  },

  {
    аrea: 133.73,
    tileName: '10A',
    imgSmall: '10A_s.jpg',
    imgLarge: '10A_l.jpg',
    areaAsText: '133.73',
    photosNames: ['10A_l1.jpg', '10A_l2.jpg']
  },

  {
    аrea: 137.12,
    tileName: '10B',
    imgSmall: '10B_s.jpg',
    imgLarge: '10B_l.jpg',
    areaAsText: '137.12',
    photosNames: ['10B_l1.jpg', '10B_l2.jpg']
  },

  {
    аrea: 137.66,
    tileName: '10C',
    imgSmall: '10C_s.jpg',
    imgLarge: '10C_l.jpg',
    areaAsText: '137.66',
    photosNames: ['10C_l1.jpg', '10C_l2.jpg']
  },

  {
    аrea: 137.9,
    tileName: '11A',
    imgSmall: '11A_s.jpg',
    imgLarge: '11A_l.jpg',
    areaAsText: '137.90',
    photosNames: ['11A_l1.jpg', '11A_l2.jpg']
  },

  {
    аrea: 138.54,
    tileName: '11B',
    imgSmall: '11B_s.jpg',
    imgLarge: '11B_l.jpg',
    areaAsText: '138.54',
    photosNames: ['11B_l1.jpg', '11B_l2.jpg']
  },

  {
    аrea: 140,
    tileName: '11C',
    imgSmall: '11C_s.jpg',
    imgLarge: '11C_l.jpg',
    areaAsText: '140.00',
    photosNames: ['11C_l1.jpg', '11C_l2.jpg']
  },

  {
    аrea: 160,
    tileName: '12A',
    imgSmall: '12A_s.jpg',
    imgLarge: '12A_l.jpg',
    areaAsText: '160.00',
    photosNames: ['12A_l1.jpg', '12A_l2.jpg']
  },

  {
    аrea: 179.55,
    tileName: '12B',
    imgSmall: '12B_s.jpg',
    imgLarge: '12B_l.jpg',
    areaAsText: '179.55',
    photosNames: ['12B_l1.jpg', '12B_l2.jpg']
  },

  {
    аrea: 206,
    tileName: '12C',
    imgSmall: '12C_s.jpg',
    imgLarge: '12C_l.jpg',
    areaAsText: '206.00',
    photosNames: ['12C_l1.jpg', '12C_l2.jpg', '12C_l3.jpg']
  },

  {
    аrea: 519,
    tileName: '13A',
    imgSmall: '13A_s.jpg',
    imgLarge: '13A_l.jpg',
    areaAsText: '519.00',
    photosNames: ['13A_l1.jpg', '13A_l2.jpg']
  },

  {
    аrea: 86.22,
    tileName: '13B',
    imgSmall: '13B_s.jpg',
    imgLarge: '13B_l.jpg',
    areaAsText: '86.22',
    photosNames: ['13B_l1.jpg', '13B_l2.jpg']
  },

  {
    аrea: 127.06,
    tileName: '13C',
    imgSmall: '13C_s.jpg',
    imgLarge: '13C_l.jpg',
    areaAsText: '127.06',
    photosNames: ['13C_l1.jpg', '13C_l2.jpg']
  }
];
