
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Comment } from './../model/comment';
import { COMMENTS } from './../data/coments-list';


@Injectable({
  providedIn: 'root'
})
export class CommentService {

constructor() { }
getComments(): Observable<Comment[]> {
  // TODO: send the message _after_ fetching the heroes
  // this.messageService.add('HeroService: fetched heroes');
  return of(COMMENTS);
}

getComment(id: number): Observable<Comment> {
  // TODO: send the message _after_ fetching the hero
  // this.messageService.add(`HeroService: fetched hero id=${id}`);
  return of(COMMENTS.find(comment => comment.id === id));
}
}


